import { useEffect, useState, useRef } from "react";
import { Box, Stack, Button, Typography, Drawer, List, ListItem, ListItemText, Divider, Paper, TextField } from "@mui/material";
import { useWindowSize } from "../../../utils/useWindowSize";

import { CommonUtils } from "../../../utils/common_utils";

import { userState, appConfig } from "../../../interface/MainInterface";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";

import { HttpAdminApi } from "../../../interface/admin-api";
import { HttpChannelApi } from "../../../interface/channel-api";
import dayjs from "dayjs";

import VodMediaList from "./VodMediaList";
import VideoPreviewModule from "../Preview/VideoPreviewModule";

import LoadingCircle from "../../../utils/LoadingCircle";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

export let player: any = null;

interface propsType {
  userState: userState;
  channelInfo: any;
  heigth: number;
  callback: any;
}

const cUtils = new CommonUtils();
const adminApi = new HttpAdminApi();
const channelApi = new HttpChannelApi();

const VodManagement = (props: propsType) => {
  const windowSize = useWindowSize();
  const timerRef: any = useRef(null); // setTimeout
  const drawerRef: any = useRef();
  const videoPreviewRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [vodList, setVodList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [nowPlayIndex, setNowPlayIndex] = useState("");

  const [vodStartHour, setVodStartHour] = useState("0");
  const [vodStartMinute, setVodStartMinute] = useState("0");
  const [vodStartSecond, setVodStartSecond] = useState("0");
  const [vodEndHour, setVodEndHour] = useState("0");
  const [vodEndMinute, setVodEndMinute] = useState("0");
  const [vodEndSecond, setVodEndSecond] = useState("0");

  // 하이라이트 시작시간
  const [highStartHour, setHighStartHour] = useState("0");
  const [highStartMinute, setHighStartMinute] = useState("0");
  const [highStartSecond, setHighStartSecond] = useState("0");

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setVodList([]);
      setOpen(false);
      setNowPlayIndex("");
    };
  }, []);

  // 레코딩 리스트 조회
  const get_vod_list = async () => {
    if (cUtils.isEmptyObj(props.channelInfo)) return;

    const param = {
      command: "get_vod_list",
      broad_seq: props.channelInfo.broad_seq,
    };

    setIsLoading(true);

    const res = await adminApi.post(param);
    if (res.code === "200") {
      console.log("get_vod_list : ", res);
      setVodList(res.response.vod_list);
    }

    setIsLoading(false);
  };

  // 채널에 VOD 지정하기
  const setVodforChannel = async (record_root: string, started_at: string) => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.channelInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    if (!window.confirm("선택하신 영상을 VOD로 등록하시겠습니까?")) return;
    setIsLoading(true);
    const param: any = {
      command: "update_channel",
      broad_info: {
        pk: props.channelInfo.channel_pk,
        broad_seq: props.channelInfo.broad_seq,
        broad_title: props.channelInfo.broad_title,
        broad_desc: props.channelInfo.broad_desc,
        broad_start_tm: props.channelInfo.broad_start_tm,
        broad_stop_tm: props.channelInfo.broad_stop_tm,
        broad_prod_list: props.channelInfo.broad_prod_list,
        broad_notice: props.channelInfo.broad_notice,
        broad_status: "VOD",
        vod_url: "/" + record_root + "/media/hls/master.m3u8",
        vod_start_sec: props.channelInfo.vod_start_sec,
        vod_end_sec: props.channelInfo.vod_end_sec,
        vod_started_at: started_at,
        high_start_sec: props.channelInfo.high_start_sec,
        password: props.channelInfo.password,
        extra_type: props.channelInfo.extra_type,
        progress_store: props.channelInfo.progress_store,
        host_id: props.channelInfo.host_id,
        channel_img: props.channelInfo.channel_img,
        channel_template: props.channelInfo.channel_template,
        livart_broad_seq: props.channelInfo.livart_broad_seq
      },
      request_user_id: props.userState.id,
    };
    const res = await channelApi.post(param);
    setIsLoading(false);
    if (res.result_code === "200") {
      alert("VOD를 등록 완료하였습니다.");
      props.callback({ command: "change_vod" });
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
  };

  // VOD 해제하기
  const unSetVodforChannel = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.channelInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    if (!window.confirm("VOD를 하제하시겠습니까?")) return;
    setIsLoading(true);
    const param: any = {
      command: "update_channel",
      broad_info: {
        pk: props.channelInfo.channel_pk,
        broad_seq: props.channelInfo.broad_seq,
        broad_title: props.channelInfo.broad_title,
        broad_desc: props.channelInfo.broad_desc,
        broad_start_tm: props.channelInfo.broad_start_tm,
        broad_stop_tm: props.channelInfo.broad_stop_tm,
        broad_prod_list: props.channelInfo.broad_prod_list,
        broad_notice: props.channelInfo.broad_notice,
        broad_status: "STOP",
        vod_url: "",
        vod_started_at: "",
        vod_start_sec: 0,
        vod_end_sec: 0,
        high_start_sec: 0,
        password: props.channelInfo.password,
        extra_type: props.channelInfo.extra_type,
        progress_store: props.channelInfo.progress_store,
        host_id: props.channelInfo.host_id,
        channel_img: props.channelInfo.channel_img,
        channel_template: props.channelInfo.channel_template,
        livart_broad_seq: props.channelInfo.livart_broad_seq
      },
      request_user_id: props.userState.id,
    };

    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      alert("등록된 VOD를 해제 완료하였습니다.");
      props.callback({ command: "change_vod" });
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setIsLoading(false);
  };

  const viewVideo = (videoInfo: any) => {
    const bucketRoot = videoInfo.stream_info.bucket_root;
    const playbackUrl = appConfig.vod_cloudfront_endpoint + "/" + bucketRoot + "/media/hls/master.m3u8";
    videoPreviewRef.current?.play(playbackUrl);
    setNowPlayIndex(videoInfo.sk); // 현재 플레이중인 동영상 표시하기 위해서
  };

  useEffect(() => {
    if (nowPlayIndex !== "") {
      timerRef.current = setTimeout(() => {
        drawerRef.current.scroll({ top: 0, left: 0, behavior: "smooth" });
      }, 300);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [nowPlayIndex]);

  const closeVideo = () => {
    videoPreviewRef.current?.play("");
    setNowPlayIndex("");
  };

  // VOD Play time 지정하기
  const saveVodStartTime = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.channelInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    const hsec = parseInt(vodStartHour) * 60 * 60;
    const msec = parseInt(vodStartMinute) * 60;
    const sec = parseInt(vodStartSecond) * 1;
    const totalSec = hsec + msec + sec;

    const hEsec = parseInt(vodEndHour) * 60 * 60;
    const mEsec = parseInt(vodEndMinute) * 60;
    const eSec = parseInt(vodEndSecond) * 1;
    const totalESec = hEsec + mEsec + eSec;

    setIsLoading(true);
    const param: any = {
      command: "update_channel",
      broad_info: {
        pk: props.channelInfo.channel_pk,
        broad_seq: props.channelInfo.broad_seq,
        broad_title: props.channelInfo.broad_title,
        broad_desc: props.channelInfo.broad_desc,
        broad_start_tm: props.channelInfo.broad_start_tm,
        broad_stop_tm: props.channelInfo.broad_stop_tm,
        broad_prod_list: props.channelInfo.broad_prod_list,
        broad_notice: props.channelInfo.broad_notice,
        broad_status: props.channelInfo.broad_status,
        vod_url: props.channelInfo.vod_url.replace(appConfig.vod_cloudfront_endpoint, ""),
        vod_start_sec: totalSec,
        vod_end_sec: totalESec,
        vod_started_at: props.channelInfo.vod_started_at,
        high_start_sec: props.channelInfo.high_start_sec,
        password: props.channelInfo.password,
        extra_type: props.channelInfo.extra_type,
        progress_store: props.channelInfo.progress_store,
        host_id: props.channelInfo.host_id,
        channel_img: props.channelInfo.channel_img,
        channel_template: props.channelInfo.channel_template,
        livart_broad_seq: props.channelInfo.livart_broad_seq
      },
      request_user_id: props.userState.id,
    };

    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      props.callback({ command: "change_vod" });
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setIsLoading(false);
  };

  const saveHighStartTime = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.channelInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    const hsec = parseInt(highStartHour) * 60 * 60;
    const msec = parseInt(highStartMinute) * 60;
    const sec = parseInt(highStartSecond) * 1;
    const totalSec = hsec + msec + sec;

    setIsLoading(true);
    const param: any = {
      command: "update_channel",
      broad_info: {
        pk: props.channelInfo.channel_pk,
        broad_seq: props.channelInfo.broad_seq,
        broad_title: props.channelInfo.broad_title,
        broad_desc: props.channelInfo.broad_desc,
        broad_start_tm: props.channelInfo.broad_start_tm,
        broad_stop_tm: props.channelInfo.broad_stop_tm,
        broad_prod_list: props.channelInfo.broad_prod_list,
        broad_notice: props.channelInfo.broad_notice,
        broad_status: props.channelInfo.broad_status,
        vod_url: props.channelInfo.vod_url.replace(appConfig.vod_cloudfront_endpoint, ""),
        vod_start_sec: props.channelInfo.vod_start_sec,
        vod_end_sec: props.channelInfo.vod_end_sec,
        vod_started_at: props.channelInfo.vod_started_at,
        high_start_sec: totalSec,
        password: props.channelInfo.password,
        extra_type: props.channelInfo.extra_type,
        progress_store: props.channelInfo.progress_store,
        host_id: props.channelInfo.host_id,
        channel_img: props.channelInfo.channel_img,
        channel_template: props.channelInfo.channel_template,
        livart_broad_seq: props.channelInfo.livart_broad_seq
      },
      request_user_id: props.userState.id,
    };

    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      props.callback({ command: "change_vod" });
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (props.channelInfo?.vod_start_sec > 0) {
      const t = cUtils.convertSecToTime(props.channelInfo.vod_start_sec);
      const t2 = cUtils.convertSecToTime(props.channelInfo.vod_end_sec);
      setVodStartHour(t.hours);
      setVodStartMinute(t.minutes);
      setVodStartSecond(t.seconds);
      setVodEndHour(t2.hours);
      setVodEndMinute(t2.minutes);
      setVodEndSecond(t2.seconds);
    } else {
      setVodStartHour("0");
      setVodStartMinute("0");
      setVodStartSecond("0");
      setVodEndHour("0");
      setVodEndMinute("0");
      setVodEndSecond("0");
    }

    if (props.channelInfo?.high_start_sec > 0) {
      const t3 = cUtils.convertSecToTime(props.channelInfo.high_start_sec);
      setHighStartHour(t3.hours);
      setHighStartMinute(t3.minutes);
      setHighStartSecond(t3.seconds);
    } else {
      setHighStartHour("0");
      setHighStartMinute("0");
      setHighStartSecond("0");
    }
  }, [props.channelInfo]);

  return (
    <>
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() => {
          get_vod_list();
          setOpen(true);
        }}
      >
        <Stack direction={"column"} spacing={0} sx={{ backgroundColor: "#1876d2", color: "#ffffff", borderRadius: "7px", p: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <VideoSettingsIcon />
          </Box>
          <Typography sx={{ fontSize: "14px", textAlign: "center" }}>VOD 관리</Typography>
        </Stack>
      </Box>
      <Drawer
        anchor={windowSize.width > 768 ? "right" : "top"}
        open={open}
        onClose={() => {
          setNowPlayIndex("");
          setOpen(false);
        }}
      >
        <Box ref={drawerRef} sx={{ overflow: "auto" }}>
          <Box sx={{ p: 1 }}>
            <Stack direction={windowSize.width > 768 ? "row" : "column"} spacing={1}>
              {windowSize.width < 768 && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setNowPlayIndex("");
                    setOpen(false);
                  }}
                >
                  닫기
                </Button>
              )}
              <Box
                sx={{
                  position: "relative",
                  width:
                    nowPlayIndex !== "" ? `${windowSize.width > 768 ? ((props.heigth - 16) * 9) / 16 : windowSize.width - 16}px` : "0px",
                  minWidth:
                    nowPlayIndex !== "" ? `${windowSize.width > 768 ? ((props.heigth - 16) * 9) / 16 : windowSize.width - 16}px` : "0px",
                  height: nowPlayIndex !== "" ? props.heigth - 16 : 0,
                }}
              >
                <VideoPreviewModule ref={videoPreviewRef} />
              </Box>

              <List sx={{ width: "100%" }} component="nav">
                {vodList.map((vodInfo: any, index: number) => (
                  <Box key={`vod-list-${index}`}>
                    <ListItem sx={{ ml: "0px !important" }}>
                      <Stack
                        direction={windowSize.width > 768 ? "row" : "column"}
                        spacing={windowSize.width > 768 ? 2 : 1}
                        sx={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}
                      >
                        <ListItemText
                          primary={`${dayjs(new Date(vodInfo.stream_info.recording_started_at)).format("YYYY-MM-DD HH:mm")} 녹화영상`}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", pl: windowSize.width > 768 ? 10 : 0 }}>
                          <Stack direction={"row"} spacing={2}>
                            <Button
                              variant="outlined"
                              color={nowPlayIndex === vodInfo.sk ? "error" : "primary"}
                              onClick={() => {
                                if (nowPlayIndex === vodInfo.sk) closeVideo();
                                else viewVideo(vodInfo);
                              }}
                            >
                              {nowPlayIndex === vodInfo.sk ? "닫기" : "보기"}
                            </Button>
                            <VodMediaList userState={props.userState} recordInfo={vodInfo} broadInfo={props.channelInfo} />
                            {props.channelInfo.vod_url ===
                            `${appConfig.vod_cloudfront_endpoint}/${vodInfo.stream_info.bucket_root}/media/hls/master.m3u8` ? (
                              <Button
                                variant="contained"
                                sx={{ minWidth: 120 }}
                                color="error"
                                onClick={() => {
                                  unSetVodforChannel();
                                }}
                              >
                                등록된 VOD
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                sx={{ minWidth: 120 }}
                                onClick={() => {
                                  setVodforChannel(
                                    vodInfo.stream_info.bucket_root,
                                    dayjs(new Date(vodInfo.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString()
                                  );
                                }}
                              >
                                VOD로 등록
                              </Button>
                            )}
                          </Stack>
                        </Box>
                      </Stack>
                    </ListItem>
                    <Divider />
                    {props.channelInfo.vod_url ===
                      `${appConfig.vod_cloudfront_endpoint}/${vodInfo.stream_info.bucket_root}/media/hls/master.m3u8` && (
                      <>
                        <Box>
                          <Box sx={{ p: 2 }}>
                            <Stack direction={"column"} spacing={2}>
                              <Box>
                                <Typography sx={{ fontWeight: "700" }}>{"vod 시간 설정"}</Typography>
                              </Box>
                              <Paper elevation={5}>
                                <Box sx={{ p: 1 }}>
                                  <Stack direction={"column"} spacing={1}>
                                    <Box>
                                      <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>풀영상</Typography>
                                    </Box>
                                    <Divider />
                                    <Box>
                                      <Stack direction={windowSize.width > 768 ? "row" : "column"} spacing={1}>
                                        <TextField
                                          id="start_hour"
                                          label="hh"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={vodStartHour}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setVodStartHour("0");
                                            } else {
                                              setVodStartHour(e.target.value);
                                            }
                                          }}
                                        />
                                        <TextField
                                          id="start_minute"
                                          label="mm"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={vodStartMinute}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setVodStartMinute("0");
                                            } else {
                                              setVodStartMinute(e.target.value);
                                            }
                                          }}
                                        />
                                        <TextField
                                          id="start_second"
                                          label="ss"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={vodStartSecond}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setVodStartSecond("0");
                                            } else {
                                              setVodStartSecond(e.target.value);
                                            }
                                          }}
                                        />
                                        <Button
                                          variant="contained"
                                          className="bt-md"
                                          sx={{ minWidth: 200 }}
                                          fullWidth={windowSize.width < 768}
                                          onClick={() => {
                                            saveVodStartTime();
                                          }}
                                        >
                                          시작시간저장
                                        </Button>
                                      </Stack>
                                    </Box>
                                    <Divider />
                                    <Box>
                                      <Stack direction={windowSize.width > 768 ? "row" : "column"} spacing={1}>
                                        <TextField
                                          id="end_hour"
                                          label="hh"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={vodEndHour}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setVodEndHour("0");
                                            } else {
                                              setVodEndHour(e.target.value);
                                            }
                                          }}
                                        />
                                        <TextField
                                          id="end_minute"
                                          label="mm"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={vodEndMinute}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setVodEndMinute("0");
                                            } else {
                                              setVodEndMinute(e.target.value);
                                            }
                                          }}
                                        />
                                        <TextField
                                          id="end_second"
                                          label="ss"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={vodEndSecond}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setVodEndSecond("0");
                                            } else {
                                              setVodEndSecond(e.target.value);
                                            }
                                          }}
                                        />
                                        <Button
                                          variant="contained"
                                          className="bt-md"
                                          sx={{ minWidth: 200 }}
                                          fullWidth={windowSize.width < 768}
                                          onClick={() => {
                                            saveVodStartTime();
                                          }}
                                        >
                                          종료시간저장
                                        </Button>
                                      </Stack>
                                    </Box>
                                  </Stack>
                                </Box>
                              </Paper>
                              <Paper elevation={5}>
                                <Box sx={{ p: 1 }}>
                                  <Stack direction={"column"} spacing={1}>
                                    <Box>
                                      <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>하이라이트</Typography>
                                    </Box>
                                    <Divider />
                                    <Box>
                                      <Stack direction={windowSize.width > 768 ? "row" : "column"} spacing={1}>
                                        <TextField
                                          id="start_hour"
                                          label="hh"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={highStartHour}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setHighStartHour("0");
                                            } else {
                                              setHighStartHour(e.target.value);
                                            }
                                          }}
                                        />
                                        <TextField
                                          id="start_minute"
                                          label="mm"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={highStartMinute}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setHighStartMinute("0");
                                            } else {
                                              setHighStartMinute(e.target.value);
                                            }
                                          }}
                                        />
                                        <TextField
                                          id="start_second"
                                          label="ss"
                                          type="number"
                                          size="small"
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={highStartSecond}
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              setHighStartSecond("0");
                                            } else {
                                              setHighStartSecond(e.target.value);
                                            }
                                          }}
                                        />
                                        <Button
                                          variant="contained"
                                          className="bt-md"
                                          sx={{ minWidth: 200 }}
                                          fullWidth={windowSize.width < 768}
                                          onClick={() => {
                                            saveHighStartTime();
                                          }}
                                        >
                                          시작시간저장
                                        </Button>
                                      </Stack>
                                    </Box>
                                  </Stack>
                                </Box>
                              </Paper>
                            </Stack>
                          </Box>
                        </Box>
                        <Divider />
                      </>
                    )}
                  </Box>
                ))}
              </List>
              {windowSize.width < 768 && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setNowPlayIndex("");
                    setOpen(false);
                  }}
                >
                  닫기
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </Drawer>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default VodManagement;
